import React, {useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import {Link, useHistory} from "react-router-dom";

function Checkout()
{
    const history = useHistory();

    // if(!localStorage.getItem('auth_token')){
    // history.push('/');
    // swal("Warning","Login to go to Cart Page", "error");
    // }

    const [loading, setLoading] = useState(true);
    const [fake_user_id, setFakeUserId] = useState('');
    const [cart, setCart] = useState([]);
    var totalCartPrice = 0;

    // console.log(localStorage.getItem('fake_user_id'));

    // const data = {
    //     pera: localStorage.getItem('fake_user_id')
    // }

    const data = {
        pera: localStorage.getItem('fake_user_id')
    }


    const [checkoutInput, setCheckoutInput] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        napomena: '',
    });

    const [error, setError] = useState([]);

    useEffect(() => {

        let isMounted = true;

        axios.post(`/api/carts/`, data ).then(res=>{

            if(isMounted)
            {

                if(res.data.status === 200)
                {
                    setCart(res.data.cart);
                    setLoading(false);
                }

                else if(res.data.status === 401)
                {
                    history.push('/');
                    swal("Warning",res.data.message,"error");
                }
            }

        });

        return () => {
            isMounted = false
        };
    }, [ history]);

    const handleInput = (e) => {
        e.persist();
        setCheckoutInput({...checkoutInput, [e.target.name]: e.target.value});
    }

    console.log(cart);

    const submitOrder = (e) => {
        e.preventDefault();

        const data = {

            firstname: checkoutInput.firstname,
            lastname: checkoutInput.lastname,
            phone: checkoutInput.phone,
            email: checkoutInput.email,
            address: checkoutInput.address,
            city: checkoutInput.city,
            state: checkoutInput.state,
            zipcode: checkoutInput.zipcode,
            remark:checkoutInput.remark,
            fake_user_id: cart[0].user_id,


        }

        axios.post(`/api/place-order`, data).then(res=>{
            if(res.data.status === 200)
            {
                swal("Order Placed Successfully",res.data.message,"success");
                setError([]);
                history.push('/thank-you');
            }
            else if(res.data.status === 422)
            {
                swal("All fields are mandatory", "","error");
                setError(res.data.errors);
            }
        });
    }

    if(loading)
    {
        return <h4>Учитавам наруџбеницу ...</h4>
    }

    var checkout_HTML = '';
    if(cart.length > 0)
    {
        checkout_HTML = <div>
          <div className="row">

              <div className="col-md-7">
                  <div className="card">
                      <div className="card-header">
                          <h4>Подаци о наручиоцу</h4>
                      </div>
                      <div className="card-body">

                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>Име</label>
                                      <input type="text" name="firstname" onChange={handleInput} value={checkoutInput.firstname} className="form-control" />
                                      <small className="text-danger">{error.firstname}</small>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>Презиме</label>
                                      <input type="text" name="lastname" onChange={handleInput} value={checkoutInput.lastname} className="form-control" />
                                      <small className="text-danger">{error.lastname}</small>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>Број телефона</label>
                                      <input type="text" name="phone" onChange={handleInput} value={checkoutInput.phone} className="form-control" />
                                      <small className="text-danger">{error.phone}</small>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>E-mаил</label>
                                      <input type="text" name="email" onChange={handleInput} value={checkoutInput.email} className="form-control" />
                                      <small className="text-email">{error.firstname}</small>
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="form-group mb-3">
                                      <label>Адреса</label>
                                      <textarea rows="3" name="address" onChange={handleInput} value={checkoutInput.address} className="form-control"></textarea>
                                      <small className="text-danger">{error.address}</small>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>Град</label>
                                      <input type="text" name="city" onChange={handleInput} value={checkoutInput.city} className="form-control" />
                                      <small className="text-danger">{error.city}</small>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>Држава</label>
                                      <input type="text" name="state" onChange={handleInput} value={checkoutInput.state} className="form-control" />
                                      <small className="text-danger">{error.state}</small>

                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group mb-3">
                                      <label>Поштански број</label>
                                      <input type="text" name="zipcode" onChange={handleInput} value={checkoutInput.zipcode} className="form-control" />
                                      <small className="text-danger">{error.zipcode}</small>
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="form-group mb-3">
                                      <label>Напомена</label>
                                      <textarea rows="3" name="remark" onChange={handleInput} value={checkoutInput.remark} className="form-control"></textarea>
                                      <small className="text-danger">{error.remark}</small>
                                  </div>
                              </div>
                              <div className="col-md-12">
                                  <div className="form-group text-end">
                                      <button type="button" className="btn btn-primary" onClick={submitOrder}>Наручи</button>
                                  </div>
                              </div>

                          </div>

                      </div>
                  </div>
              </div>
              <div className="col-md-5">
                  <table className="table table-bordered">
                      <thead>
                      <tr>
                          <th width="50%">Производ</th>
                          <th>Цена</th>
                          <th>Количина</th>
                          <th>Укупно</th>
                      </tr>
                      </thead>
                      <tbody>
                      {cart.map( (item, idx) => {
                          totalCartPrice += item.product.selling_price * item.product_qty;
                          return (
                              <tr key={idx}>
                                  <td>{item.product.name}</td>
                                  <td>{item.product.selling_price}</td>
                                  <td>{item.product_qty}</td>
                                  <td>{item.product.selling_price * item.product_qty}</td>
                              </tr>
                          )
                      } )}
                      <tr>
                          <td colSpan="2" className="text-end fw-bold">Укупно</td>
                          <td colSpan="2" className="text-end fw-bold">{totalCartPrice} RSD</td>
                      </tr>
                      </tbody>
                  </table>
              </div>

          </div>
      </div>
    }
    else
    {
        checkout_HTML = <div>
            <div className="card card-body py-5 text-center shadow-sm">
                <h4>Вача шопинг карта је празна. Ви сте на страни наруџбенице</h4>
            </div>
        </div>
    }

    return (
        <div>
            <div className="py bg-warning">
                {/*<div className="container">*/}
                {/*    <h6>Home / Checkout</h6>*/}
                {/*</div>*/}
            </div>

            <div className="py-4">
                <div className="container">
                    {checkout_HTML}
                </div>
            </div>
        </div>
    )
}

export default Checkout;