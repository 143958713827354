import React from "react";
// import {Link} from "react-router-dom";

function UsefulLinks() {
    return (
        <div className="container pt-5">
            <div className="card p-5">
                <h2>Корисни линкови</h2>
                <a href='https://www.zdravljenemacenu.com/tamjan-za-kamen-u-bubregu/'  target="_blank">Тамјан за камен у бубрегу</a>
                <a href='https://www.google.com/amp/s/sensa.mondo.rs/zdravo-telo/zdravlje-i-holisticka-medicina/amp/a36309/Za-sta-se-sve-koristi-tamjan-i-koja-su-lekovita-svojstva.html' target="_blank">Лековита својства тамјана</a>
                <a href='https://www.rts.rs/lat/magazin/priroda/4184114/da-li-ste-znali-ovih-sest-stvari-o-tamjanu.html' target="_blank">Шест ствари о тамјану</a>
                <a href='https://www.agrosaveti.rs/blog/tamjan-lek-za-dusu-i-telo/' target="_blank">Лек за душу и тело</a>
                <a href='https://borbazaistinu.rs/tamjan-i-njegova-neverovatna-moc-2/' target="_blank">Тамјанова невероватна моћ</a>
            </div>
        </div>

    );
}

export default UsefulLinks;