import React from "react";
import '../../scss/components/Home.scss';
import Logo from "../../assets/images/logo.png";
// import Tamjan from "../../assets/images/t_tamjan.jpg";
import Tamjan from "../../assets/images/t_tamjan.jpg";
import TamjanPolica from "../../assets/images/tamjan-polica.jpg";
import TamjanIspodVidea from "../../assets/images/ispod-videa.png";
import TamjanLogo from "../../assets/images/tamjan-logo.jpg";
import Promocija from "../../assets/images/promocija-kraljevski.jpg";
import Promocija2 from "../../assets/images/briket-trobojka.jpg";
import Promocija2a from "../../assets/images/opis-trobojka.jpg";
import Promocija3 from "../../assets/images/medicinski.png";
import Promocija4 from "../../assets/images/radosnik.jpg";
import Desavanja from "../../assets/images/desavanja_10_23.jpg";
import Desavanja2 from "../../assets/images/cestitka-2.jpg";
import Desavanja3 from "../../assets/images/otac2.jpg";
import DesavanjaUskrs from "../../assets/images/uskrs24.jpg";
import Desavanja4 from "../../assets/images/desavanja-posta.jpg";

// import Promocija from "../../assets/images/izvor.jpg";
// import Instagram from "../../assets/images/instagram.png";
// import Facebook from "../../assets/images/facebook.png";
// import Youtube from "../../assets/images/youtube.png";
import Tamjanvideo from "../../assets/images/sveti-sava.mp4";
import Gallery from "./Gallery";
import {Link} from "react-router-dom";
// import { Accordion, Card } from "react-bootstrap";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";

import { MdOutlineExpandMore } from "react-icons/md";



// import "../../../node_modules/video-react/dist/video-react.css"; // import css
// @import "~video-react/styles/scss/video-react"; // or import scss
// import { Player } from 'video-react';





function Home() {

	var bgColors = {"Video": "#51342c"};
	return (
		<div className="body">

			<div className="container-fluid p-4">
					<div className="row row-cols-1 row-cols-md-3 g-4">
						<div className="col">
							<div className="card h-100 p-4">
								<div>
									<img src={Tamjan}  width="100%" alt='tamjan'/>
								</div>
								<br/>
								{/*<h4>Срећан Божић</h4>*/}

								<h5>Поштовани, радује нас да посећујете прву домаћу интернет страницу о једином правом природном тамјану Босвелија.</h5>
									<h5>Као што су казивали Отац Тадеј, Свети Серафим Саровски и многи други светитељи, тамјан је чудо Божије.</h5>
									<h5>Овде ћете сазнати како, када и који тамјан можете да користите и зашто баш њега.</h5>
								<h5>Поред  најквалитетнијег и највећег избора <Link to={'/collections/tamjani'}><span style={{color:'#067267'}}>природних тамјана</span></Link>, у понуди су и многобројни благотворни <Link to={'/collections/proizvodi_od_tamjana'}><span style={{color:'#067267'}}>производи од тамјана</span></Link>  за дух, душу и тело, као и <Link to={'/collections/prateci_program'}><span style={{color:'#067267'}}>пратећи програм</span></Link>, <Link to={'/collections/ulja_difuzeri_isparivaci'}><span style={{color:'#067267'}}>уља дифузери и испаривачи</span></Link> ...
								</h5><h5>Посетите нашу <Link to={'/collections'}><span style={{color:'#067267'}}> , интернет продавницу</span></Link></h5>

								{/* <h5>Тамјан и производи су Божији дар који желимо да поделимо са свима који желе да осете његове благотворне моћи. </h5> */}
								{/*<h5>Делује на дух, душу и тело и свакако на простор који се кади, без обзира да ли људи верују у моћ и деловање природног тамјана или не.</h5>*/}
								{/*<h5>Његов дим и мирис ће прочистити простор од лоше енергије која се налази у њему.🌿</h5>*/}

									{/*<div className="card-body">*/}
									{/*	/!*<h5 className="card-title">Card title</h5>*!/*/}
									{/*	/!*<p className="card-text">This is a wider card with supporting text below as a*!/*/}
									{/*	/!*	natural lead-in to additional content. This content is a little bit*!/*/}
									{/*	/!*	longer.</p>*!/*/}
									{/*</div>*/}
									{/*<div className="card-footer">*/}
									{/*	<small className="text-muted">Last updated 3 mins ago</small>*/}
									{/*</div>*/}
							</div>
						</div>
						<div className="col">
							<div className="card h-100 p-4">
								{/*<div>*/}
								{/*	<img src={Promocija2} width="100%" alt='tamjan'/>*/}
								{/*</div>*/}


								{/*<div className="card-body">*/}
								{/*	<h4 className="card-title red">ПРОМОЦИЈА 🌿🔥♨️</h4>*/}
								{/*	<div>*/}
								{/*		<img src={Promocija2a} width="100%" alt='tamjan'/>*/}
								{/*	</div>*/}

								{/*</div>*/}
								{/*<div className="card-footer">*/}
								{/*	/!*<small className="text-muted">Last updated 3 mins ago</small>*!/*/}
								{/*	/!*<button href="http://localhost:3000/collections/tamjani/kraljevski" className="btn btn-primary form-control">Vise o tome ...</button>*!/*/}
								{/*	/!*<Link className="btn btn-primary text-white form-control" to="collections/tamjani/casni_krst_prom"  >Vise o tome ...</Link>*!/*/}
								{/*	<Link className="btn btn-primary text-white form-control"*/}
								{/*		  to="collections/prateci_program/vaskrsnja_ponuda">Више о томе ...</Link>*/}
								{/*</div>*/}
								{/*<br/><br/>*/}
								{/*<br/>*/}
								<div>
									<img src={Promocija3} width="100%" alt='tamjan'/>
								</div>

								<div className="card-body">
									{/*<h4 className="card-title red">ПРОМОЦИЈА 🌿🔥♨️</h4>*/}
									<h4 className="card-text"> Природни тамјан за топљење каменца у бубрегу и жучи.
										- земља порекла: Оман
										Узмите један комад Краљевског тамјана (величине грашка) проперите га испод
										текуће воде и пијте га пре доручка на празан стомак 21 дан.
										Паковање садржи 28 комадића природног тамјана.
									</h4>
								</div>
								<div className="card-footer">
									{/*<small className="text-muted">Last updated 3 mins ago</small>*/}
									{/*<button href="http://localhost:3000/collections/tamjani/kraljevski" className="btn btn-primary form-control">Vise o tome ...</button>*/}
									{/*<Link className="btn btn-primary text-white form-control" to="collections/tamjani/casni_krst_prom"  >Vise o tome ...</Link>*/}
									<Link className="btn btn-primary text-white form-control"
										  to="collections/tamjani/medicinski">Више о томе ...</Link>
								</div>
								<br/><br/><br/>

								<div>
									<img src={Promocija4} width="100%" alt='tamjan'/>
								</div>

								<div className="card-body">
									<h4 className="card-title red">ЛЕТЊА ПРОМОЦИЈА 🌿🔥♨️</h4>
									<h4 className="card-text">


										РАДОСНИК природни тамјан <br/>25 грама.<br/>
										🌿
										МЕЛЕМ од органског тамјана
										,<br/>
										🌿
										Брикет 22мм/30мин.<br/>
										🌿
										Поцинкована кадионица са дрвеном дршком.<br/>

										Упаковано у поклон кутију.<br/>
										ПРОМОТИВНА ЦЕНА 1.880,00 дин.


									</h4>
								</div>
								<div className="card-footer">
									{/*<small className="text-muted">Last updated 3 mins ago</small>*/}
									{/*<button href="http://localhost:3000/collections/tamjani/kraljevski" className="btn btn-primary form-control">Vise o tome ...</button>*/}
									{/*<Link className="btn btn-primary text-white form-control" to="collections/tamjani/casni_krst_prom"  >Vise o tome ...</Link>*/}
									<Link className="btn btn-primary text-white form-control"
										  to="collections/tamjani/radosnik-promocija">Више о томе ...</Link>
								</div>

							</div>


						</div>
						<div className="col">
							<div className="card p-4">

								<iframe width="100%" height="300"
										src="https://www.youtube.com/embed/vuwrzSzYc2Q?si=hj-dXET7Gplt8XVP"

										title="YouTube video player" frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen></iframe>
								<h4 className="card-title">
									НЕЋЕТЕ ВЕРОВАТИ да постоји кућа природног тамјана у Новом Саду.
								</h4>


								{/*<video width='100%' controls>*/}
								{/*	<source src={Tamjanvideo} type="video/mp4"/>*/}
								{/*</video>*/}
								{/*<div className="card-body">*/}
								{/*<h4 className="card-title">Добро дошли на Београдски сајам књига од: 21-29.октобра*/}
								{/*	/10⁰⁰-21⁰⁰час.<br/>*/}
								{/*На штанду ПРИРОДНИ ТАМЈАН у ХАЛИ 4 очекује вас навећи избор природног тамјана и*/}
								{/*производа. Уз дегустације воде од тамјана очекује вас<br/>*/}
								{/*ЕКСКЛУЗИВНА ПРОДАЈА и ПРОМОЦИЈА КЊИГЕ:📚<br/>*/}
								{/*МИСИОНАРСКИ ДНЕВНИК<br/>*/}
								{/*@otac_predrag_popovic<br/>*/}
								{/*🌿У четвртак 26.октобар<br/>*/}
								{/*12⁰⁰-14⁰⁰ и 16⁰⁰-18⁰⁰ часова<br/>*/}
								{/*🌿У суботу 28.октобар<br/>*/}
								{/*14⁰⁰-18⁰⁰ часова</h4>*/}
								{/*	<h5 className="card-text">Ближи нам се најрадоснији празник - БОЖИЋ. </h5>*/}
								{/*	<h4>Драга браћо и сестре,*/}
								{/*		честитамо вам најрадоснији празник Рођења  Господа Исуса Христа, и Нову молитвену годину  пуну љубави, мира и радости Господње,*/}
								{/*		уз сверадосни божићни поздрав:*/}
									{/*		Мир Божји, Христос се роди!*/}
									{/*		Ваистину Христос се роди. </h4>*/}

									{/*</div>*/}
									{/*<div className="card-footer">*/}
									{/*	<small className="text-muted">Last updated 3 mins ago</small>*/}

									{/*</div>*/}

									<div className="card h-100 mt-4">

										<a href="https://borbazaistinu.rs/tamjan-i-njegova-neverovatna-moc-2/"
										   target="_blank"><img src={TamjanIspodVidea} width="100%" alt='tamjan'/></a>
										<br/>
										<h4>Тамјан и његова невероватна моћ</h4>
										<a style={{color: '#067267'}}
										   href="https://borbazaistinu.rs/tamjan-i-njegova-neverovatna-moc-2/"
										   target="_blank">borbazaistinu.rs</a>


									</div>
									<div className="card h-100 p-4">
										<div>
											<img src={Promocija} width="100%" alt='tamjan'/>
										</div>

										<div className="card-body">
											<h4 className="card-title red">ПРОМОЦИЈА КРАЉЕВСКОГ
												природног тамјана 3 у 1</h4>
											<h4 className="card-text">САДРЖИ:<br/>
												1. ЈЕДНОСТАВНО се решите каменца у бубрегу или жучи.
												🌿<br/>
												2. ЛАКО направите здраву воду од тамјана.
												🌿<br/>
												3.ОКАДИТЕ ваш простор у коме боравите♨️
												🌿<br/>
												Све то можете учинити са овим оригиналним паковањем органског КРАЉЕВСКОГ
												природног тамјана.
												🌿<br/>
												Ручно направљена храстова
												дрвена кутију са брикетом 🔥
												садржи довољно тамјана за:<br/> ✅ једну терапију<br/>
												✅ 10 литара воде<br/>
												✅ 7 пута да окадите
												☦️🌿♥️<br/>
												Цена овог оригиналног паковања је само 1.980 дин.
											</h4>
										</div>
										<div className="card-footer">
											{/*<small className="text-muted">Last updated 3 mins ago</small>*/}
											{/*<button href="http://localhost:3000/collections/tamjani/kraljevski" className="btn btn-primary form-control">Vise o tome ...</button>*/}
											{/*<Link className="btn btn-primary text-white form-control" to="collections/tamjani/casni_krst_prom"  >Vise o tome ...</Link>*/}
											<Link className="btn btn-primary text-white form-control"
												  to="collections/tamjani/promocija-kraljevski">Више о томе ...</Link>
										</div>
									</div>


							</div>
						</div>
					</div>
				<div className="row">

					<div className="col-md-4">
						<div className="card p-4 mt-4">
							<h2>Дешавања:</h2>


							<div className="card h-100 mt-4">

								{/*<img src={Desavanja4} width="100%" alt='tamjan'/>*/}
								{/*<br/>*/}
								<img src={DesavanjaUskrs} width="100%" alt='tamjan'/>
								<br/>
								<img src={Desavanja3} width="100%" alt='tamjan'/>
								<br/>

								<h4>Добро дошли на Београдски сајам књига од 21-29 Октобра 2023.
									На нашем штанду у хали 4 очекује вас навећи избор природног тамјана и
									производа.</h4>
								<br/>

							</div>
							<div className="card h-100 mt-4">

								<img src={Desavanja2} width="100%" alt='tamjan'/>
								<br/>

								{/*<h4>Потписивање књига је предвиђено за ђачки дан 26тог - четвртак и субота 28ми -*/}
								{/*	последњи дан сајма..</h4>*/}
								{/*<br/>*/}

							</div>
							{/*<div className="card h-100 mt-4">*/}

								{/*<img src={Desavanja} width="100%" alt='tamjan'/>*/}
								{/*<br/>*/}

								{/*<h4>Добро дошли на Београдски сајам књига од 21-29 Октобра 2023.*/}
								{/*	На нашем штанду у хали 4 очекује вас навећи избор природног тамјана и*/}
								{/*	производа.</h4>*/}
								<br/>

							{/*</div>*/}

							<div className="card h-100 mt-4">

								<img src={TamjanPolica} width="100%" alt='tamjan'/>
								<br/>

								<h4>Полицу са нашим производима можете наћи у патријаршијским продавницама широм
										Београда, многобројним црквеним продавницама, храмовима и манастирима широм
										Србије 🇷🇸</h4>
									<br/>

								</div>


								<div className="card h-100 mt-4">

									<img src={TamjanLogo} width="100%" alt='tamjan'/>
									<br/>
									<h4>Приликом куповине наших природних производа, молимо вас
										да обратите пажњу на заштитни знак ℬℴ𝓈𝓌ℯ𝓁𝓁𝒾𝒶 🌿 који гарантује оргиналност и
										квалитет истих.</h4>


								</div>
							</div>
						</div>

						<div className="col-md-8">
							<div className="card p-4 mt-4">

								{/*<h5>Поштовани, радује нас да посећујете прву домаћу интернет страницу о једином правом природном тамјану Босвелија.</h5>*/}
								{/*<h5>Као што су казивали Отац Тадеј, Свети Серафим Саровски и многи други светитељи, тамјан је чудо Божије.</h5>*/}
								{/*		<h5>Овде ћете сазнати како, када и који тамјан можете да користите и зашто баш њега.</h5>*/}



									{/*<h5>Поред  најквалитетнијег и највећег избора <Link to={'/collections/tamjani'}><span style={{color:'#067267'}}>природних тамјана</span></Link>, у понуди су и многобројни благотворни <Link to={'/collections/proizvodi_od_tamjana'}><span style={{color:'#067267'}}>производи од тамјана</span></Link>  за дух, душу и тело, као и <Link to={'/collections/prateci_program'}><span style={{color:'#067267'}}>пратећи програм</span></Link>, попут кадионица, испаривача, брикета ...*/}
									{/*</h5><h5>Посетите нашу <Link to={'/collections'}><span style={{color:'#067267'}}> интернет продавницу</span></Link></h5>*/}
							</div>
							<div className="card p-4 mt-4">
								<h2>Најчешћа питања и одговори: </h2>
								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Зашто је Исус добио природни тамјан на дар?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Природни тамјан је, уз злато и смирну, један од дарова којим су мудраци са Истока даривали новорођеног Христа. Тај тамјан је потицао од врсте Boswellia sacra која расте у данашњем Оману.
											Три дара имала су духовно значење: злато као симбол краљевства на земљи, тамјан као симбол божанства, и смирна као симбол смрти.
											Смирна (Миро) се такоће користи као уље за помазање, тамјан као Божански мирис,  а злато као овоземаљска драгоценост.
										</h5>
									</AccordionDetails>
								</Accordion>
								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>За чега је природни тамјан добар и може ли се користити као лек?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Природни тамјан поседује многобројна благотворна дејства и употребе у свим верско-религијским обредима, медицини, козметици, исхрани... Кроз многобројне студије и тестирања потврђено је да инценсол ацетат, састојак смоле „Boswellia“, смањује анксиозност и делује антидепресивно.  Грчки и Римски лекари су од тамјана правили противупалне масти и кад. Тамјан, као и смирна су међу најстаријим лековима на свету.
										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Шта симболизује тамјан? </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Тамјан се употребљава у верским и духовним обредима хиљадама година.  Он означава присутност и силу Бога и светога Духа. Тамјан је симбол светости и праведности. У хришћанству, тамјан представља љубав према вери и топлоту душе домаћина.

										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Може  ли се природни тамјан користити свакодневно?  </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Свакако да може у редовном кађењу простора, на испаривачу, у води за пиће, као и кроз коришћење уља од тамјана. Фондација за артритис препоручује узимање  капсуле од природног тамјана Boswellia (400 мг) три пута на дан, да би се босвелијским киселинама ојачало тело. Препорука су капсуле које садрже 60% босвелијске киселине. Кађење природним тамјаном активира јонске канале у мозгу који ублажавају анксиозност или депресију.


										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Можете ли се спавати с упаљеним испаривачем? </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Испаривачи  за тамјан или уље емитују смирујући и опуштајући мирис у ваздуху - тако да да их можете користити у спаваћим собама, радним просторијама, амбулантама,чекаоницама... чак и у дечијим собама.                                                          Предности: пријатан, лековит и вишесатни мирис без дима, уз  миран сан.

										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Да ли је сигурно жвакати тамјан ? </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Природни тамјан је вероватно најстарија жвакаћа гума, користи се хиљадама година за дезинфекцију и јачање зуба и десни. Истраживачи су открили да су Римљани користили противупалне ефекте тамјана за негу зуба. Да би то постигли, жвакали су тамјан који се топи и развија помало горак укус. Код старих Римљана, тамјан је могла себи приуштити само виша класа становништва. Тамјан за жвакање и данас је чест у неким културама. Нарочито у Индији, Оману и Египту људи жваћу тамјан и онда га прогутају. Тамјан  делује против упале и инфекција у стомаку, а такође и ојачава тело и здравље у целини.


										</h5>
									</AccordionDetails>
								</Accordion>
								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Која су лековита својства тамјана? </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Помаже у зацељивању рана, чирева, красти, хемороида и упала. Етерично  уље природног тамјана има противупална, диуретичка и умирујућа терапијска својства. Топи каменац у бубрегу и решава пробавне проблеме, смањује анксиозност и делује антидепресивно.
											Такође су веома позната и његова антивирусна и антимикробна деловања.


										</h5>
									</AccordionDetails>
								</Accordion>
								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Одакле долази најбољи природни тамјан?  </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Дрво „Boswellia“ – sacra пореклом је са јужног Арапског полуострва, Индије и региона Африке. Најранији и најпознатији извор тамјана је земља Оман која хиљадама година шаље ову мирисну смолу широм света.
										</h5>
									</AccordionDetails>
								</Accordion>
								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Како купити квалитетан природни тамјан?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Упутство за куповину правог квалитетног природног тамјана: ако негде купујете тамјан, треба да обратите пажњу на квалитет и не купујете најјефтинији производ лошег сагоревања са разним хемијским бојама и синтетичким мирисима.  Овакви „лажни“ тамјани  углавном краткотрајно миришу и врло брзо сагоревају, а због поменутих вештачких додатака могу бити штетни по ваше здравље. Распитајте се код продавца од које  врсте „Boswellia“ дрвета потиче тамјан. То вам је најсигурнији начин да сазнате да ли се заиста ради о правом природном тамјану. Запамтите, тамјан је осушена смола са дрвета и не може никако бити ружичасте, тиркизне или црвене боје, као ни правилног облика. Тамјан је у облику груменчића-суза разних величина и облика.

										</h5>
									</AccordionDetails>
								</Accordion>
								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Да ли је тамјан добар против  депресије?   </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>За природни тамјан је познато да помаже подизању расположења и уравнотеживању хормона, посебно код жена. Једна студија која је користила тамјан заједно са уљима која су поспешила опуштање, открила је да је ова комбинација ефикасна у ублажавању депресије код смртно болесних пацијената и да  помаже у лечењу болова.

										</h5>
									</AccordionDetails>
								</Accordion>


								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Да ли тамјан отапа каменац у бубрегу?</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Медицински је доказано да Краљевски тамјан отапа каменац у бубрегу и мокраћним каналима, чак и оне каменчиће који су се већ активирали. Узмајте 21 дан по један мали грумен тамјана (величине грашка) пре доручка на празан стомак претходно га оправши над млазом воде.  Након тога направите паузу од недељу дана. Тамјан ће лагано почети растапати каменце у бубрегу. По потреби поновите терапију.

										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>
												Како се прави вода од тамјана?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Укусна и здрава ВОДА од природног тамјана прави се једноставно, брзо и лако. У чисту стаклену боцу сипајте један литар изворске или куповне воде (или воду из чесме прокувати и охладити). У боцу убаците око 6-7 грама природног тамјана БЕЛИ АНЂЕО или Краљевски-Royal и оставите преко ноћи да одстоји. Ујутру је вода спремна за пиће. Пре сваке употребе промућкајте боцу. Ову воду можете пити током читавог дана. По жељи засладите природним медом и додајте мало цеђеног лимуна. Тамјан који сте убацили у боцу можете користити за око пет литара воде тако што ћете доливати воду. Редовним коришћењем ове воде:
											<br/>- ојачаћете своје крвне судове
											<br/>- прочистити организам од токсина
											<br/>- побољшати нервни систем.
										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Како на организам делује инхалација тамјаном или његовим уљем?</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>За инхалацију вам је потребно око 5 грама природног тамјана и 1 литар кључале воде. У врелу воду ставите природни тамјан, пешкиром прекријте главу и удишите пару 5 минута, највише 3 пута дневно. Уместо тамјана можете ставити неколико капи 100% етеричног уља од тамјана.
											Ова инхалација помаже код:
											<br/>- проблема са циркулацијом
											<br/>- ефикасна је у лечењу
											<br/>- прехладе, астме, синуситиса, упале плућа, као и код проблема са кашљем и дисањем
											<br/>- регенерише кожу лица и чисти поре
											<br/>- идеална је за употребу пре спавања, опушта и доприноси здравијем сну.
										</h5>
									</AccordionDetails>
								</Accordion>


								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Да ли је тамјан добар за плућа?</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Терапија са састојцима ПРИРОДНОГ ТАМЈАНА, посебно босвелијским киселинама које се налазе у 100% етеричном уљу од органског тамјана има изузетно добре резултате у лечењу бронхијалне астме. За све проблеме са плућима и болестима дисајних органа, најбољи резултати се постижу кроз инхалирање, нарочито коришћењем етеричног уља.
										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Да ли је тамјан антибактеријски?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Добро утемељеном анализом смоле тамјана научници су успели да открију супстанце са антибактеријским дејством и тако поткрепе резултате које су већ постигли у пракси.
										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Да ли природни тамјан делије антивирусно?</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Његов дим и пара
											<br/>- прочишћава ваздух, јер тамјан има антивирусно и антимикробно дејство. Примена тамјана дуго је саставни део Центра за туморе мозга
											<br/>- Charité (једна од највећих универзитетских болница у Европи). Према речима неурохирурга др Мартина Мисцх, биљни лек од природног тамјана поштеђује
											<br/>- пацијента коришћења кортизона и његових нуспојава, попут задржавања воде или високог крвног притиска.

										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Које су благодати коришћења 100 % етеричног уља од тамјана?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Благотворно делује на:
											<br/>- нервни систем и ублажавање стреса, јер када се уље од тамјана испарава у ваздуху, оно подстиче осећај мира и опуштања
											<br/>- помаже код мигрена и главобоље утрљавањем неколико капи уља на слепоочнице
											<br/>- успешно помаже код плућних болести и у лечењу астме, синуситиса и бронхитиса, као и код проблема са кашљем и тзв звиждањем
											<br/>- због својих ароматерапеутских благодати, тамјан уље ће вам помоћи приликом проблема са спавањем (ставите неколико капи уља у руке, протрљајте дланове и удахните пре него што пођете на спавање).
											<br/>- обнавља ћелијску функцију и враћа кожи здрав изглед, уједначава тонус коже и умањује мрље.
											<br/>- окрепљује крв и смирује срце, анксиозност и депресију, а уједно је и снажан антиоксиданс.

											Коришћење етеричног уља тамјана помаже у чишћењу ваздуха од патогена, омогућавајући вам удисање чишћег ваздуха.Веома је корисно користити фа током сезоне грипа због његових антисептичких и дезинфекционих својстава која елиминишу прехладу и клице грипа присутне у ваздуху.
										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Која је сврха природног тамјана и смирне?  </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Природни тамјан и смирна се од  давнина користе у верским церемонијама, као и у лечењу разних тегоба. У традиционалној кинеској и ајурведској медицини се углавном користе за лечење хроничних болести. Главни хемијски састојци тамјана и смирне су терпеноиди, босвелијске киселине, етерична уља...
										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Зашто је природни тамјан толико скуп? </h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Тамјан се прави од осушене смоле  дрвета под називом  Босвелија, од којих су понеки веома ретки.  У веома сложеним радњама, потребно је направити урезе у кори дрвета, сачекати да смола исцури, затим да се осуши, сакупи, сортира, пакује и превезе са удаљености од неколико хиљада километара  из земаља у којима углавном владају политички немири и ограничења у извозу.                                                                                                                Велика светска потражња за природним тамјаном је узроковала претерано урезивање Босвелија-(Boswellia) стабла што је довело до драстичног изумирања дрвећа, недостатка тамјана и високих цена.

										</h5>
									</AccordionDetails>
								</Accordion>

								<Accordion square>
									<AccordionSummary  style={{ backgroundColor: '#067267', color: 'white'}} expandIcon={<MdOutlineExpandMore color="white"/>}>
										<Typography>
											<h5>Од чега зависи квалитет природног тамјана?
											</h5>
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<h5>Квалитет природног тамјана зависи од многих фактора:

											<br/>•        Локације стабла
											<br/>•        Уреза „ране“ на дрвету
											<br/>•        Сунчевог зрачења
											<br/>•        Старости стабала
											<br/>•        Хранљивих састојака земље
											<br/>•        Микроклиме на локацији
											<br/>•        Сезонске климе, као што су влага и температура
											<br/>•        Садржаја песка и коре у „сузама“
											<br/>•        Величине комада „суза“ (тамјана) због различитих протока смоле/тамјана
										</h5>
									</AccordionDetails>
								</Accordion>




								{/*Photo Gallery*/}
								<div   style = {{marginTop: 50, backgroundColor: '#067267', padding: '3em', color: '#fff'}}>
									<h2 style={{textAlign: 'center'}}>Фото галерија</h2>
									<Gallery />
								</div>
							</div>

						</div>

					</div>
			</div>


		</div>


	);
}

export default Home;