import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import PublicRoute from "./PublicRoute";
import Register from "./components/frontend/auth/Register";
import Login from "./components/frontend/auth/Login";
import AdminPrivateRoute from './AdminPrivateRoute';
import ReactGA from 'react-ga';

import Page403 from "./components/errors/Page403";
import Page404 from "./components/errors/Page404";
import axios from "axios";





// axios.defaults.baseURL = "http://127.0.0.1:8000";
// axios.defaults.baseURL = "http://api.ecommerce.predrag-bozic.com";
const baseURL = process.env.REACT_APP_BASE_URL
axios.defaults.baseURL = baseURL
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config){
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});



function App() {

        useEffect( () => {
            ReactGA.initialize('UA-238146885-1');

            // to report page view
            ReactGA.pageview(window.location.pathname + window.location.search);

        }, [])

        return (

            <div className="App">

                <Router>
                    <Switch>
                        {/*<Route exact path="/" component={HomeOld} />*/}
                        {/*<Route path="/about" component={About} />*/}
                        {/*<Route path="/contact" component={Contact} />*/}

                        <AdminPrivateRoute path="/admin" name="Admin" />
                        <PublicRoute path="/" name="Home" />

                        <Route path="/403" component={Page403} />
                        <Route path="/404" component={Page404} />


                        {/*<Route exact path="/login"><Login /></Route>*/}
                        {/*<Route exact path="/register"><Register /></Route>*/}

                        <Route path="/login">
                            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Login />}
                        </Route>

                        <Route path="/register">
                            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Register />}
                        </Route>

                        {/*<Route path="/admin" name="Admin" render={ (props) => <MasterLayout { ...props } />} />*/}

                    </Switch>
                </Router>

            </div>



        );
}

export default App;