import React from "react";
import background from "../../assets/images/tamjan_back.jpg";

function Contact() {
    return (
        <div>
            <div className="container-fluid" style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: 'center',
                height: '500px',
            }}>



            </div>
            <div className="container text-center p-5" style={{background: "#fff"}}>
                <h2>
                    Kontakt informacije:
                </h2>
                <h2>
                    email: tamjanprirodni@gmail.com<br/>
                    tel: +381 67 711 7171<br/>
                    Темеринска улица 79<br/>
                    21000 Novi Sad
                    Srbija
                </h2>
                <h2><br/><br/>
                    Радно време:<br/>
                    Радним данима од 9-17 сати<br/>
                    Суботом од 9-14 сати<br/>
                    Недељом и црквеним празницима не радимо<br/>
                </h2>
            </div>

        </div>

    );
}

export default Contact;