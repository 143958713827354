import React, {useContext, useState} from "react";
import '../../scss/components/Navbar.scss';
import {Link, useHistory} from "react-router-dom";
import { MdHome, MdShoppingCart, MdLogin, MdLogout } from "react-icons/md";
import {FiUsers} from "react-icons/fi";
import Logo from "../../assets/images/logo.png"
import background from "../../assets/images/tamjan_back.jpg";
import swal from "sweetalert";
import axios from "axios";
import {CountContext} from "./FrontendLayout";


function Navbar() {

	const { count } = useContext(CountContext);

	const [show,changeShow] = useState("false");

	function hamburger(){
		changeShow((show)=>{ return !show });
	}

	const history = useHistory();
	const logoutSubmit = (e) => {
		e.preventDefault();

		axios.post(`/api/logout`).then(res => {
			if(res.data.status === 200)
			{
				localStorage.removeItem('auth_token');
				localStorage.removeItem('auth_name');
				swal('Success', res.data.message, "success");
				history.push('/');
			}
		});
	}
	// var bgColors = {"Tamjan": "#c3e0d9"};
	// var bgColors = {"NavbarOld": "#51342c"};
	var bgColors = {"Navbar": "#067267"};
	// var bgColors2 = {"logoback": '#51342c'};
	// var bgColors = {"NavbarOld": "#91786d"};
	var AuthButtons = '';
	if(!localStorage.getItem('auth_token'))
	{
		AuthButtons = (

			<ul className="navbar-nav">
				<li className="nav-item">
					<Link className="nav-link active" to="/login"><FiUsers size="1.5em" /></Link>
				</li>
				{/*<li className="nav-item">*/}
				{/*	<Link className="nav-link" to="/register">Register</Link>*/}
				{/*</li>*/}
			</ul>

		)
	}
	else
	{
		AuthButtons = (
			<li className="nav-item">
				<button type="button" onClick={logoutSubmit} className="nav-link btn btn-danger btn-sm text-white"><MdLogout size="1.5em" /></button>
			</li>
		)
	}
	return (
		<>
			<div className="container-fluid" style={{
				backgroundImage: `url(${background})`,
				backgroundRepeat: 'repeat-x',
				backgroundPosition: 'center',
			}}>
				<ul className="navbar-nav mx-auto">
					<Link className="navbar-brand d-none d-lg-block center-block" to="/"><img src={Logo} alt='tamjan'/></Link>
				</ul>
			</div>

			{/*<div className="container-fluid center-block">*/}
			{/*	*/}
			{/*</div>*/}

			<nav className="navbar navbar-expand-lg navbar-dark shadow sticky-top" style={{backgroundColor: bgColors.Navbar}}>

				<div className="container-fluid">
					<Link className="navbar-brand d-lg-none" to="/"><img src={Logo} alt='tamjan' /></Link>



					<button className="navbar-toggler border border-info text-info"
							onClick={ hamburger }>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className={show ? 'collapse navbar-collapse' : 'collapse navbar-collapse active'}>
						<ul className="navbar-nav mx-auto"onClick={hamburger} >
							<li className="nav-item text-danger">
								<Link className="nav-link active" to="/"><MdHome size="1.5em" /></Link>
							</li>
							<li className="nav-item text-danger">
								<Link className="nav-link" to="/collections">Продавница</Link>
							</li>



							<li className="nav-item text-danger">
								<Link className="nav-link" to="/about">О тамјану</Link>
							</li>

							<li className="nav-item text-danger">
								<Link className="nav-link" to="/selling_places">Продајна места</Link>
							</li>

							{/*<Link className="navbar-brand d-none d-lg-block" to="/"><img src={Logo} alt=''/></Link>*/}

							{/*<li className="nav-item text-danger">*/}
							{/*	<Link className="nav-link" to="/#">Блог</Link>*/}
							{/*</li>*/}

							<li className="nav-item text-danger">
								<Link className="nav-link" to="/usefullinks">Корисни линкови </Link>
							</li>

							<li className="nav-item text-danger">
								<Link className="nav-link" to="/contact">Контакт</Link>
							</li>

							{/*<li className="nav-item text-danger">*/}
							{/*	<Link className="nav-link" to="/cart"> <MdShoppingCart className='korpa' size="1.5em"/><span*/}
							{/*		className="cart-total--item">{ count }</span></Link>*/}
							{/*</li>*/}

							{/*{AuthButtons}*/}




						</ul>
						<Link className="nav-link" to="/cart"> <MdShoppingCart className='korpa' size="1.5em"/><span
							className="cart-total--item">{ count }</span></Link>

					</div>

				</div>

			</nav>
		</>
	)
}

export default Navbar;