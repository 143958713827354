import React from "react";

import Slider01 from "./slider/SimpleSlider01";
import Slider02 from "./slider/SimpleSlider02";
import Slider03 from "./slider/SimpleSlider03";
import Slider04 from "./slider/SimpleSlider04";
import Slider05 from "./slider/SimpleSlider05";
import Slider06 from "./slider/SimpleSlider06";
import Slider07 from "./slider/SimpleSlider07";



function SellingPlaces() {


	return (
		<div>
			<div className="py-4 kuler0">
				<div className="container-fluid p-4 kuler0">
					<div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
						<Slider01 />
						<Slider02 />
						<Slider03 />
						<Slider04 />
						<Slider05 />
						<Slider06 />
						<Slider07 />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SellingPlaces;