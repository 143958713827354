import React, {useContext, useEffect, useState} from "react";
import { MdAddShoppingCart } from "react-icons/md";
import axios from "axios";
import swal from "sweetalert";
import {Link, useHistory} from "react-router-dom";

import ReactHtmlParser from 'react-html-parser';
import {CountContext} from "../../../layouts/frontend/FrontendLayout";


function ProductDetail(props)
{

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [fake_user_id, setFakeUserId] = useState('');
    const { count, setCount } = useContext(CountContext);
    var totalItems = 0;

    useEffect(() => {

        let isMounted = true;


        const category_slug = props.match.params.category;
        const product_slug = props.match.params.product;
        if(localStorage.getItem('fake_user_id'))
        {
            setFakeUserId(localStorage.getItem('fake_user_id'))
        }
        else
        {
            const fake_user_id = Math.floor((Math.random() * 999999) + 100000);
            localStorage.setItem('fake_user_id', JSON.stringify(fake_user_id));
            setFakeUserId(localStorage.getItem('fake_user_id'))
        }




        axios.get(`/api/viewproductdetail/${category_slug}/${product_slug}`).then(res=>{



            if(isMounted)
            {


                if(res.data.status === 200)
                {

                    setProduct(res.data.product);
                    setLoading(false);
                }

                else if(res.data.status === 404)
                {
                    history.push('/collections');
                    swal("Warning",res.data.message,"error");
                }
            }

        });

        return () => {
            isMounted = false
        };
    }, [props.match.params.category, props.match.params.product, history, fake_user_id]);

    const handleDecrement = () => {
        if(quantity >1) {
            setQuantity(prevCount => prevCount - 1 );
        }
    }
    const handleIncrement = () => {
        setQuantity(prevCount => prevCount + 1 );
    }

    const submitAddtocart = (e) => {
        e.preventDefault();
        totalItems = count + quantity;
        setCount(totalItems);
        const data = {
            fake_user_id: fake_user_id,
            product_id: product.id,
            product_qty: quantity,

        }

        axios.post(`/api/add-to-cart`, data).then(res=>{
            if(res.data.status === 201){
                swal("Успешно",res.data.message,"success");
            } else if(res.data.status === 409) {
                //Already added to cart
                swal("Успешно", res.data.message,"success");
            }else if(res.data.status === 401) {
                swal("Грешка", res.data.message,"error");

            }else if(res.data.status === 404) {
                swal("Пажња", res.data.message,"warning");
            }
        });
    }

    if(loading)
    {
        return <h4>Loading Products Detail ...</h4>
    }
    else
    {
        var avaial_stock = '';

        if(product.qty > 0)
        {
            avaial_stock = <div>
                <label className="btn-sm btn-success px-4 mt-2">На стању</label>

                <div className="row">
                    <div className="col-md-3 mt-3">
                        <div className="input-group">
                            <button type="button" onClick={handleDecrement} className="input-group-text">-</button>
                            <div className="form-control text-center">{quantity}</div>
                            <button type="button" onClick={handleIncrement} className="input-group-text">+</button>
                        </div>
                    </div>
                    <div className="col-md-3 mt-3">
                        <button type="button" className="btn btn-primary" onClick={submitAddtocart}>Додај у корпу</button>
                        {/*<button type="button" className="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#staticBackdrop">*/}
                        {/*    Dodaj u korpu</button>*/}
                    </div>
                </div>
            </div>
        }
        else
        {
            avaial_stock = <div>
                <label className="btn-sm btn-danger px-4 mt-2">Нема на стању</label>
            </div>
        }

    }

    return (
        <div>

            <div className="py-3 kuler2">
                {/*<div className="container">*/}
                {/*    <h6>Prodavnica / {product.category.name} / {product.name}</h6>*/}
                {/*</div>*/}
            </div>

            <div className="py-3 kuler0">
                <div className="container">
                    <div className="row">

                        <div className="col-md-4 border-end">
                            <div className="card">

                                <img src={product.photo[0].url} className="w-100" alt={product.name} />
                            </div>
                            {/*<img src={product.photo.url} className="w-100" alt={product.name} />*/}

                            {/*<img src={product.photo[1].url} className="w-100" alt={product.name} />*/}

                        </div>

                        <div className="col-md-8">
                            <h4>
                                {product.name}
                                {/*<span className="float-end badge btn-sm btn-danger badge-pil">{product.brand} </span>*/}
                            </h4>
                            {
                                product.brand ? <>- земља порекла: {product.brand} </> : null
                            }

                            {ReactHtmlParser(product.description)}
                            <h4 className="mb-1">
                                 {product.selling_price} дин
                                {/*<s className="ms-2"> Rs: {product.original_price} </s>*/}
                            </h4>
                            <div>
                                {avaial_stock}
                            </div>


                            {/*<button type="button" className="btn btn-danger mt-3">Add to Wishlist</button>*/}
                        </div>
                    </div>
                </div>

            </div>

            {/*<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">*/}
            {/*    <div class="modal-dialog">*/}
            {/*        <div class="modal-content">*/}
            {/*            <div class="modal-header">*/}
            {/*                <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>*/}
            {/*                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
            {/*            </div>*/}
            {/*            <div class="modal-body">*/}
            {/*                <p>Predlazemo vam da kreirate nalog na nasem sajtu, kako bi se jos vise uprostio proces narucivanja, a kao registrovani korisnik, dobijate besplatnu dostavu za sve narudzbine.</p>*/}
            {/*            </div>*/}
            {/*            <div class="modal-footer">*/}
            {/*                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Hocu da nastavim narudzbinu bez registracije</button>*/}
            {/*                <Link  type="button" class="btn btn-primary" data-bs-dismiss="modal" to="../../register">Prihvatam kreiranje naloga</Link>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default ProductDetail;