import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
// import slide01 from "../../../assets/images/slider01/s01.jpg";
// import slide02 from "../../../assets/images/slider01/s02.jpg";
// import slide03 from "../../../assets/images/slider01/s03.jpg";
// import {Link} from "react-router-dom";

export default function SimpleSlider01() {

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		// speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		pauseOnHover: true,
		autoplaySpeed: 5000,
		lazyLoad: true,
		adaptiveHeight: false,
	};
	return (
		<div>
			<div className="card-header">
				<div className="card-title">
					<div>БЕСЕДА православна књижара</div>
					<p>Нови Сад, Змај Јовина 4, тел: 021 521 240</p>
				</div>
			</div>
			<Slider {...settings}>

				<div>
					<div className="card h-100 p-3">
						<div>
							{/*<img src={slide01} className="w-100" alt="slider" />*/}
							<img src={process.env.PUBLIC_URL + '/images/slider01/s01.jpg'}  className="w-100" alt="slider" />

						</div>

					</div>
				</div>
				<div>
					<div className="card h-100 p-3">
						<div>
							{/*<img src={slide02} className="w-100" alt="slider" />*/}
							<img src={process.env.PUBLIC_URL + '/images/slider01/s02.jpg'}  className="w-100" alt="slider" />
						</div>

					</div>
				</div>
				<div>
					<div className="card h-100 p-3">
						<div>
							{/*<img src={slide03} className="w-100" alt="slider" />*/}
							<img src={process.env.PUBLIC_URL + '/images/slider03/s01.jpg'}  className="w-100" alt="slider" />
						</div>
					</div>
				</div>
			</Slider>

		</div>




	);
}