import React from "react";

function About() {
    return (
        <div className="container pt-5">
            <div className="card p-5">
                <h2>О тамјану</h2>
                <h4>Природни тамјан ℬℴ𝓈𝓌ℯ𝓁𝓁𝒾𝒶 🌿

                    Тамјан је чудо Божије.
                    Није довољно само проветравати просторију, треба је и окадити тамјаном, јер неке отрове који остају у ваздуху уништава само природни тамјан.
                    И зла сила бежи када палимо природни тамјан,
                    указивао је отац Тадеј Витовнички

                    🌿
                </h4>
                <h4>

                    Да ли знате шта задобијате редовним паљењем природног тамјана Boswellia у вашем дому?
                    Најпре би смо вам поменули шта ћете изгубити.
                    Из вашег дома нестаће лоша енергија која се кроз мисли, речи и дела временом накупила.
                    Нестаће и страхови, туга, безвољност, несигурност...
                    Паљењем природног тамјана Boswellia, на место њих у ваш дом ући ће добра енергија, радост, воља, полет и нова животна снага. Ојачаћете свој дух, душу и тело.
                    🌿
                    Драги љубитељи природног тамјана „Boswellia",
                    у потпуности можете бити сигурни  да су сви наши тамјани, као и производи од њих, под именом „Boswellia", потпуно природни тамјани без икаквих штетних додатних примеса као што су хемијске боје или синтетички мириси! Сви наши тамјани добијају се управо са дрвета под називом босвелија.
                    Сви наши производи паковани су у заштићеној амбалажи, ради очувања квалитета.


                    🌿
                </h4>

                <br/>
                <h4>
                    ЗАШТО је КРАЉЕВСКИ тамјан најквалитетнији и најскупљи на свету!?

                    Мало је рећи да је мирис тамјана пријатан, он је просто Божанствен, делује позитивно на наше духовно и телесно стање.
                    🌿
                    КРАЉЕВСКИ тамјан је врхунског квалитета.
                    Сакупља се са дрвета Boswellia sacra. Ручно се пробира, сортира и пакује, како би се одвојиле чисте зеленкасте „сузе“.

                    Због своје вишеструке употребе у духовне, медицинске и козметичке сврхе,
                    у читавом свету влада веома велика потражња за краљевским тамјаном. Количине и квалитет које се са дрвета добијају зависе од временских прилика, а органичен извоз из Султаната Оман је један од разлога зашто је његова цена висока.
                    Прави Краљевски тамјан (појављу се и ту фалсификати) достиже цену и преко 1.000€ за кг.
                    *Због наших пријатељских односа са власником плантаже из Омана, већ неколико годинама имамо непромењене цене и врхунски квалитет природног тамјана.

                    🌿
                </h4>
                <h4>
                    КРАЉЕВСКИ тамјан се не користи само за кађење у домовима, храмовима и у духовне сврхе. Од њега смо по провереним рецептима направили веома делотворне, здраве и корисне производе.

                    - МЕД са природним тамјаном
                    - МЕЛЕМ од природног тамјана
                    - ПРОПОЛИС са додатком уља приридног тамјана.
                    - САПУН са природним тамјаном
                    - УЉЕ 100%  етерично од Краљевског тамјана Boswellia sacra
                    - ВОДА за пиће од Краљевског тамјана.
                    - ИНХАЛАЦИЈА са тамјаном или уљем.
                    🌿
                </h4>
                <h4>
                    Природни тамјан се више хиљада година користи и у медицини, ради излечења многобројних здравствених тегоба.<br/><br/>

                </h4>
                <h4>
                    ТАМЈАН КАО ЛЕК ЗА КАМЕН У БУБРЕГУ *рецепт који сви траже<br/>

                    Медицински је доказано да Краљевски тамјан отапа каменац у бубрегу и мокраћним каналима, чак и оне каменчиће који су се већ активирали.

                    Узмајте 21 дан по један мали грумен тамјана (величине грашка) пре доручка на празан стомак претходно га оправши над млазом воде.

                    <br/>Након тога направите паузу од недељу дана. Тамјан ће лагано почети растапати каменце у бубрегу.
                    По потреби поновите терапију.


                    <br/><br/>У козметици се користи као средство за одржавање хигијене, заштите и неге коже и косе.
                    Може бити делотворан додатак у исхрани ради јачања организма.
                    Одабран је због свог врхунског квалитета.
                    Од давнина је називан најбољим тамјаном на свету, јер је управо тамјан са стабла Boswellia sacra дарован Исусу на рођењу.
                </h4>
            </div>
        </div>

    );
}

export default About;