import React from "react";

function Page403() {
	return(
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="card card-body">
						<h1>Page 403 | Forbidden </h1>
						<h3>Access Denided.! As you are not an Admin</h3>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Page403;