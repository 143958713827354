import React from "react";
import Slider from "react-slick";
// import slide01 from "../../../assets/images/slider04/s01.jpg";
// import slide02 from "../../../assets/images/slider04/s02.jpg";
// import slide03 from "../../../assets/images/slider04/s03.jpg";
// import slide04 from "../../../assets/images/slider04/s04.jpg";
// import slide05 from "../../../assets/images/slider04/s05.jpg";

import {Link} from "react-router-dom";

export default function SimpleSlider() {

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		// speed: 600,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		pauseOnHover: true,
		autoplaySpeed: 4900,
		lazyLoad: true,
		adaptiveHeight: true,
	};
	return (
		<div>
			<div className="card-header">
				<div className="card-title">
					<div>МАНАСТИР ВАВЕДЕЊА ПРЕСВЕТЕ БОГОРОДИЦЕ</div>
					<p>Сремски Карловци, Војвођанска улица</p>
				</div>
			</div>
			<Slider {...settings}>

				<div>
					<div className="card h-100 p-3">
						<div>
							<img src={process.env.PUBLIC_URL + '/images/slider04/s01.jpg'}  className="w-100" alt="slider" />
						</div>

					</div>
				</div>
				<div>
					<div className="card h-100 p-3">
						<div>
							<img src={process.env.PUBLIC_URL + '/images/slider04/s02.jpg'}  className="w-100" alt="slider" />
						</div>

					</div>
				</div>
				<div>
					<div className="card h-100 p-3">
						<div>
							<img src={process.env.PUBLIC_URL + '/images/slider04/s03.jpg'}  className="w-100" alt="slider" />
						</div>
					</div>
				</div>
				<div>
					<div className="card h-100 p-3">
						<div>
							<img src={process.env.PUBLIC_URL + '/images/slider04/s04.jpg'}  className="w-100" alt="slider" />
						</div>
					</div>
				</div>
				<div>
					<div className="card h-100 p-3">
						<div>
							<img src={process.env.PUBLIC_URL + '/images/slider04/s05.jpg'}  className="w-100" alt="slider" />
						</div>
					</div>
				</div>
			</Slider>

		</div>




	);
}