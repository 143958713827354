import React from "react";

function Page404() {
	return(
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="card card-body">
						<h1>Page 404 | Page Not Found </h1>
						<h3>Url / Page you are searching not found</h3>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Page404;