import React, {useState, useEffect} from "react";
import axios from "axios";
import {Link} from "react-router-dom";


function ViewCategory()
{
    const [loading, setLoading] = useState(true);
    const [ categories, setProductCategories ] = useState([]);
    const [errors, setErrors] = useState('');

    useEffect(() => {

        let isMountered = true;

        // axios.get('/api/get-category').then(res=>{
        //
        //     if(isMountered)
        //     {
        //         if(res.data.status === 200)
        //         {
        //             // console.log(res.data.productCategories);
        //             setProductCategories(res.data.categories);
        //             setLoading(false);
        //         }
        //     }
        // });
        axios.get('/api/get-category')
           
            .then((res)=>{
                    // console.log(res.data.productCategories);
                    setProductCategories(res.data.categories);
                    setLoading(false);
         
            })
            .catch((e) => {
                // console.log("Api Call failed");
                setErrors('Ne radi backend server');
            });
        
        return () => {
            isMountered = false;
        }
    },[]);

    if(loading)
    {
       if(errors)
       {
           return <h4>{errors} ...</h4>
       }
       else
       {
           return <h4>Loading Categories ...</h4>
       }
       
        
    }
    else
    {

        var showCategoryList = [];
        showCategoryList = categories.map( (item, idx) => {
            return (
                <div className="col-md-4" key={idx}>
                    <div className="card">
                        <Link to={`collections/${item.slug}`} >
                            <img src={item.photo.url} className="w-100" alt={item.name} />
                        </Link>
                        <div className="card-body">
                            <Link to={`collections/${item.slug}`}>
                                <h5>{item.name}</h5>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        } )
    }

    return (
        <div>
            <div className="py-3 kuler2">
                {/*<div className="container">*/}
                {/*    <h6>Category Page</h6>*/}
                {/*</div>*/}


            </div>

            <div className="py-3 kuler0">
               <div className="container">
                   <div className="row">
                       {showCategoryList}
                       {/* <div>*/}

                       {/*         {(() => {*/}
                       {/*             let showCategoryList = [];*/}
                       {/*             for (let i = 0; i < categories.length; i++) {*/}

                       {/*                 if (i !== categories.length-1) {*/}
                       {/*                     showCategoryList.push(<div key={categories[i].id}>{categories[i].name},</div>)*/}
                       {/*                 } else*/}
                       {/*                 {*/}
                       {/*                     showCategoryList.push(<div key={categories[i].id}>{categories[i].name}</div>)*/}
                       {/*                 }*/}


                       {/*             };*/}
                       {/*             // {showCategoryList.push(<div key={categories[categories.length-1].id}>{categories[categories.length-1].name}</div>)}*/}



                       {/*             return showCategoryList;*/}
                       {/*         }) ()}*/}



                       {/* </div>*/}
                   </div>
               </div>

            </div>
        </div>
    )
}

export default ViewCategory;