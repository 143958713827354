import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import swal from "sweetalert";
import {Link, Router, useHistory} from "react-router-dom";

import ReactHtmlParser from 'react-html-parser';
import {findAllByDisplayValue} from "@testing-library/react";
import login from "./auth/Login";
import {CountContext} from "../../layouts/frontend/FrontendLayout";
// import {findAllByDisplayValue} from "@testing-library/react";



function Cart() {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState([]);
    const [fake_user_id, setFakeUserId] = useState('');
    var totalCartPrice = 0;
    var totalItems = 0;
    const { setCount } = useContext(CountContext);

    console.log(localStorage.getItem('fake_user_id'));
    // console.log(count)


const data = {
    pera: localStorage.getItem('fake_user_id')
}



    useEffect(() => {

        let isMounted = true;



        setFakeUserId(localStorage.getItem('fake_user_id'));


        axios.post(`/api/carts/`, data ).then(res=>{

            if(isMounted)
            {

                if(res.data.status === 200)
                {
                    setCart(res.data.cart);
                    setLoading(false);
                }

                else if(res.data.status === 401)
                {
                    history.push('/');
                    swal("Warning",res.data.message,"error");
                }
            }

        });

        return () => {
            isMounted = false
        };
    }, [ history]);




    const handleDecrement = (cart_id) => {
        setCart(cart =>
            cart.map((item) =>
                cart_id === item.id ? {...item, product_qty: item.product_qty - (item.product_qty > 0 ? 1 : 0 ) } : item
            )

        );
        updateCartQuantity(cart_id,"dec");
    }

    const handleIncrement = (cart_id) => {
        setCart(cart =>
            cart.map((item) =>
                cart_id === item.id ? {...item, product_qty: item.product_qty + 1 } : item
            )

        );
        updateCartQuantity(cart_id,"inc");
    }

    function updateCartQuantity(cart_id,scope){

        axios.put(`/api/carts/${cart_id}/${scope}`).then(res=>{
            if(res.data.status === 200)
            {
                // swal("Success", res.data.message,"success");
            }
        });
    }

    const deleteCartItem = (e, cart_id) => {
        e.preventDefault();

        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Removing";


        axios.delete(`/api/carts/${cart_id}`).then( res => {
           if(res.data.status === 200)
           {
               swal("Успешно" , res.data.message,"success");
               thisClicked.closest("tr").remove();
           }
           else if(res.data.status === 404)
           {
               swal("Грешка" , res.data.message,"error");
               thisClicked.innerText = "Remove";
           }
        } );
    }

    if(loading)
    {
        return <h4>Учитавам детаље о производу ...</h4>
    }

    var cart_HTML = '';
    if(cart.length > 0)
    {
        cart_HTML = <div>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        {/*<th>Фото</th>*/}
                        <th>Назив производа</th>
                        <th className="text-center">Цена RSD</th>
                        <th className="text-center">Количина - ком</th>
                        <th className="text-center">Укупно RSD</th>
                        <th className="text-center">Акција</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        cart.map( (item, idx) => {

                            totalCartPrice += item.product.selling_price * item.product_qty;
                            totalItems += item.product_qty;
                            setCount(totalItems);


                            return (

                            <tr key={idx}>
                                    {/*<td width="10%">*/}
                                    {/*    <img src={item.product.photo[0].url} width="50px" height="50px"*/}
                                    {/*         alt={item.product.name}/>*/}
                                    {/*</td>*/}
                                    <td>{item.product.name}</td>
                                    <td width="15%" className="text-center">{item.product.selling_price}</td>
                                    <td width="15%">
                                        <div className="input-group">
                                            <button type="button" onClick={() => handleDecrement(item.id)}
                                                    className="input-group-text">-
                                            </button>
                                            <div className="form-control text-center">{item.product_qty}</div>
                                            <button type="button" onClick={() => handleIncrement(item.id)}
                                                    className="input-group-text">+
                                            </button>
                                        </div>
                                    </td>
                                    <td width="15%"
                                        className="text-center">{item.product.selling_price * item.product_qty}</td>
                                    <td width="10%">
                                        <button type="button" onClick={(e) => deleteCartItem(e, item.id)}
                                                className="btn btn-danger btn-sm">
                                            Избриши
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <h5 className="mt-5">Поштовани,<br/>
                        ваше поруџбине шаљемо курирском службом ПТТ/Пост Експрес.</h5>
                    <h5>Бесплатна испорука за вредност поруџбине веће од 3.999 динара. </h5>
                    {/*<h5>Proizvoda ukupno: {totalItems}</h5>*/}
                    {/*<h5>Proizvoda count: { count }</h5>*/}

                </div>
                <div className="col-md-4">
                    <div className="card card-body mt-2">
                        <h5>Укупно:
                            <span className="float-end">{totalCartPrice} RSD</span>
                        </h5>
                        <h5>За плаћање:
                            <span className="float-end">{totalCartPrice} RSD</span>
                        </h5>
                        <hr />
                        <Link to="/checkout" className="btn btn-primary">Наручи</Link>
                    </div>
                </div>
            </div>
        </div>

    }
    else
    {
        cart_HTML = <div>
            <div className="card card-body py-5 text-center shadow-sm">
               <h4>Ваша шопинг карта је празна</h4>
            </div>
        </div>
    }

    return (
        <div>


            <div className="py-3 kuler4">
                {/*<div className="container">*/}
                {/*    <h6>Home / Cart</h6>*/}
                {/*</div>*/}
            </div>

            <div className="py-4 kuler5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            {cart_HTML}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

}

export default Cart;