import React, {createContext, useState} from "react";
import {Switch, Route} from "react-router-dom";

import Navbar from "./Navbar";
import publicRoutesList from "../../routes/Publicroutelist";
import Footer from "../../components/frontend/Footer";
export const CountContext = createContext();

const FrontendLayout = () => {

    const [count, setCount] = useState(0);


    return (
        <div>
            <CountContext.Provider value={{ count, setCount }}>

                <Navbar />
                <div>

                    <Switch>
                        {publicRoutesList.map((routedata, idx) => {
                            return (
                                routedata.component && (
                                    <Route
                                        key={idx}
                                        path={routedata.path}
                                        exact={routedata.exact}
                                        name={routedata.name}
                                        render={(props) => (
                                            <routedata.component {...props} />
                                        )}
                                    />
                                )
                            )
                        })}

                    </Switch>



                </div>
                <Footer />
            </CountContext.Provider>
        </div>




    );
}

export default FrontendLayout;