import React from "react";

import Instagram from "../../assets/images/instagram.png";
import Facebook from "../../assets/images/facebook.png";
import Youtube from "../../assets/images/youtube.png";

function Footer() {
    return (
        <div className="col-12 p-0"  style={{backgroundImage: "linear-gradient(#473434, black)"}}>
            <div className="bg-dark text-center text-white">

                <div className="container p-4">
                    <section className="text-center text-white p-3">
                        <a className="m-5" href="https://www.instagram.com/tamjan_prirodni/" target="_blank">
                            <img src={Instagram}  width="60px" alt='tamjan'/>
                        </a>

                        <a className="m-5" href="https://www.facebook.com/tamjanorganski" target="_blank">
                            <img src={Facebook}  width="60px" alt='tamjan'/>
                        </a>

                        <a className="m-5" href="https://www.youtube.com/channel/UCmBFZ0yZGlf3qc6zcJb2qtA" target="_blank">
                            <img src={Youtube}  width="60px" alt='tamjan'/>
                        </a>



                    </section>

                    <div className="text-center p-3">
                        © 2024 Copyright: www.tamjan.com   Izrada web sajta:  <a href="https://predrag-bozic.org/">Predrag Bozic</a>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Footer;