import React, {useState, useEffect} from "react";
import axios from "axios";
import swal from "sweetalert";
import {Link, useHistory} from "react-router-dom";
import Tamjan from "../../../assets/images/tamjan_baner.jpg";

function ViewProduct(props)
{
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);

    // const productCount = products.length;
    const productCount = (product || []).length;

    useEffect(() => {

        let isMounted = true;

        const slug = props.match.params.slug;

        axios.get(`/api/fetchproducts/${slug}`).then(res=>{

            if(res.data.status === 200)
            {
                // console.log(res);
                setProduct(res.data.product_data.product);
                setCategory(res.data.product_data.category);
                setLoading(false);

            }

            else if(res.data.status === 400)
            {
                swal("Warning",res.data.message,"");
            }
            else if(res.data.status === 404)
            {
                history.push('/collections');
                swal("Warning",res.data.message,"error");
            }
        });

      return () => {
        isMounted = false
      };
    }, [props.match.params.slug, history]);

    if(loading)
    {
        return <h4>Loading Product ...</h4>
    }
    else
    {
        var showProductList = '';

        if(1 === 1)
        {
            showProductList = product.map( (item, idx) => {
                return (

                    <div className="col">
                        <div className="card h-100 p-3">
                            <div>
                                <Link to={`/collections/${item.category.slug}/${item.slug}`}>
                                    <img src={item.photo[0].url} className="w-100" alt={item.name} />
                                </Link>

                            </div>

                            <div className="card-body">
                                <h5 className="card-title">
                                    <Link to={`/collections/${item.category.slug}/${item.slug}`}>
                                        <h6>{item.name}</h6>
                                        <h6>
                                            {
                                                product.brand ? <>- земља порекла2: {product.brand} </> : null
                                            }
                                        </h6>
                                        <h6>{item.selling_price} дин.</h6>
                                    </Link>
                                </h5>
                                {/*<p className="card-text">This is a wider card with supporting text below as a*/}
                                {/*    natural lead-in to additional content. This content is a little bit*/}
                                {/*    longer.</p>*/}
                            </div>
                            {/*<div className="card-footer">*/}
                            {/*    <small className="text-muted">Last updated 3 mins ago</small>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                )
            } );
        }
        else
        {
            showProductList =
                <div className="col-md-12">
                    <h4>Nema proizvoda u odabranoj kategoriji {category.name}</h4>
                </div>
        }

    }

    return (
        <div>
            <div className="py-4 kuler2">
                {/*<div className="container-fluid">*/}
                {/*    <h6>Prodavnica / {category.name}</h6>*/}
                {/*</div>*/}


            </div>

            <div className="py-4 kuler0">
                <div className="container-fluid p-4 kuler0">
                    <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
                            {showProductList}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ViewProduct;